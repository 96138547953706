import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import moment from "moment";
import { errorFormating } from "utils/utils";
import "react-datepicker/dist/react-datepicker.css";
import EmployeeFunctionForm from './EmployeeFunctionForm';
import { employeesService } from "services";
import * as employeesActions from 'actions/employeesActions';

class EmployeeFunctionEdit extends React.Component {
  constructor(props) {
    super(props);

    let employee_function = {
      function_id: 0,
      priority: -1,
      is_default: true,
      ubications: [],
    };

    let isEdit = false;

    if (props.employee_function) {
      employee_function = {
        ...props.employee_function,
        function_id: props.employee_function.function.id,
      };
      isEdit = true;
    }

    console.log(employee_function);

    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      employee_function,
    };
  }

  checkValidField = (name) => {
    const { submitted, errors } = this.state;
    return submitted && errors[name] !== undefined && errors[name] !== '';
  };

  handleSubmit = async (employee_function) => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let response = '';

    const props = this.props;

    console.log(employee_function);

    if (employee_function.priority === "-1" || employee_function.priority === -1) {
      employee_function.priority = null;
    }

    employee_function.ubication_ids = employee_function.selected_ubications?.map(o => o.id) || [];

    console.log(employee_function);
    // if edit => update; else => new    
    if (this.state.isEdit) {
      response = await employeesService.updateFunction(props.employee.id, employee_function);
    }
    else {
      response = await employeesService.addFunction(props.employee.id, employee_function);
    }

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        this.props.getEmployeeFunctions(props.employee.id);
        props.handleClose();

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  };

  render() {
    const { functions, employee_ubications } = this.props;
    const { employee_function, errors, isLoading } = this.state;

    return (
      <EmployeeFunctionForm
        initialEmployeeFunction={employee_function}
        functions={functions}
        employee_ubications={employee_ubications}
        errors={errors}
        handleSubmit={this.handleSubmit}
        isLoading={isLoading}
        checkValidField={this.checkValidField}
        handleClose={this.props.handleClose}
      />
    );
  }
}

const mapStateToProps = (state, myProps) => ({
  ...state.employeesReducer,
  functions: state.functionsReducer.functions,
  employee_ubications: state.ubicationsReducer.ubications,
  employee: myProps.employee,
});

export default connect(mapStateToProps, employeesActions)(EmployeeFunctionEdit);
