import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FiPlus, FiMoreHorizontal, FiCheck } from "react-icons/fi";
import { showModal, hideModal } from "actions/modalActions";
import * as employeesActions from 'actions/employeesActions';
import EmployeeUbicationEdit from "./EmployeeUbicationEditModal";
import UbicationEdit from "../Ubications/UbicationEditModal";
import { employeesService } from "services";
import { errorFormating } from "utils/utils";


class EmployeeUbications extends React.Component {
	constructor(props) {
		super(props);
		//console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewEmployeeUbicationModal = this.openNewEmployeeUbicationModal.bind(this);
		this.openEditEmployeeUbicationModal = this.openEditEmployeeUbicationModal.bind(this);
		this.openEditUbicationModal = this.openEditUbicationModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.getUbications = this.getUbications.bind(this);
	}

	componentDidMount() {
		this.getUbications();
	}

	async getUbications() {
		const employeeId = this.props.employee.id;
		await this.props.getEmployeeUbications(employeeId);
	}

	closeModal() {
		this.props.hideModal()
	}

	openNewEmployeeUbicationModal() {
		const { ubications, employee } = this.props;

		this.props.showModal({
			open: true,
			title: 'Asignar ubicación',
			style: { width: '450px' },
			content: <EmployeeUbicationEdit handleClose={this.closeModal} ubications={ubications} employee={employee} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openEditEmployeeUbicationModal(employee_ubication) {
		const { ubications, employee } = this.props;

		this.props.showModal({
			open: true,
			title: 'Editar asignación de ubicación',
			style: { width: '350px' },
			content: <EmployeeUbicationEdit employee_ubication={employee_ubication} employee={employee} handleClose={this.closeModal} ubications={ubications} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openEditUbicationModal(employee_ubication) {
		this.props.showModal({
			open: true,
			title: 'Editar ubicación',
			style: { width: '400px' },
			content: <UbicationEdit ubication={employee_ubication.ubication} getUbications={this.getUbications} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}


	openDeleteEmployeeUbicationModal(idUbication) {
		this.props.showModal({
			open: true,
			title: 'Eliminar asignación',
			style: { width: '400px' },
			message: '¿Desea eliminar la asignación a esta ubicación? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idUbication),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
			open: true,
			title: "Error al eliminar",
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (idUbication) => {
		const response = await employeesService.removeUbication(this.props.employee.id, idUbication);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else {
			this.getUbications();
			this.closeModal();
		}
	}


	// render ubications:
	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="5" className="no-result">
					Todavía no hay ninguna ubicación asignada.
				</td>
			</tr>
		);
	}

	getRows = () => this.props.employee_ubications.map((employee_ubication) => {
		// console.log(employee_ubication);
		return (
			<tr key={employee_ubication.id}>
				<td>
					<button className="btn-transparent" onClick={() => this.openEditEmployeeUbicationModal(employee_ubication)}>{employee_ubication.ubication.name}</button>
				</td>
				<td>
					{employee_ubication.ubication.description}
				</td>
				<td>
					{employee_ubication.skill && employee_ubication.skill !== -1 ? employee_ubication.skill : '-'}
				</td>
				<td>
					{employee_ubication.is_default && <FiCheck className="tick-default" />}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openEditEmployeeUbicationModal(employee_ubication)}>Editar asignación</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openEditUbicationModal(employee_ubication)}>Editar ubicación</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openDeleteEmployeeUbicationModal(employee_ubication.id)}>Eliminar</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	render() {
		const { employee_ubications } = this.props;

		return (
			<table className="table table-groups table-zebra-reverse">
				<thead>
					<tr>
						<th>Nombre</th>
						<th>Descripción</th>
						<th>Habilidad</th>
						<th>Defecto</th>
						<th className="td-actions"><button type="button" title="Añadir ubicación" onClick={this.openNewEmployeeUbicationModal} className="btn btn-new"><FiPlus /></button></th>
					</tr>
				</thead>
				<tbody>
					{employee_ubications.length === 0 ?
						this.getEmptyRow()
						:
						this.getRows()
					}
				</tbody>
			</table>
		)
	}
}


const mapStateToProps = state => {
	return {
		employee_ubications: state.employeesReducer.employee_ubications,
		user: state.authReducer.user,
	}
}

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getEmployeeUbications: (id) => dispatch(employeesActions.getEmployeeUbications(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeUbications);