import React, { Fragment, useState } from "react";
import { FaLock, FaComment } from "react-icons/fa";
import { RiPencilFill } from "react-icons/ri";
import { ImClock } from "react-icons/im";
import { GoAlert } from "react-icons/go";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import PlanShiftHoverMenu from "components/Plans/PlanShiftHoverMenu";
import Avatar from "components/Avatar";
import { PLAN_GRID_VIEWS } from "components/Plans";
const MENU_SHIFT_ID = "menu_shift";
const MENU_DAY_ID = "menu_day";
import { getContrastColor } from "utils/utils";
import { formatSecondsToTime, calculateWorkTime } from "utils/datetime";
import { convertFromUTC } from 'utils/datetime';

function collect(props) {
	return props;
}

const getDefaultAssignedShifts = (props, hoveredAssignmentId, setHoveredAssignmentId, employee, sp_item, row, index) => sp_item.assignments.map((item) => {
	const shift = item.shift;
	const funcion = item.function;

	// console.log(sp_item);
	// console.log(item);
	// console.log(props);
	// console.log(shift);

	const key = item.id === -1 ? `empty-${row}-${index}` : item.id; // Usamos el id de la asignación como key
	const editedTimes = item.time_intervals_from_manual && item.time_intervals && item.time_intervals.length > 0;
	const day_selection = props.day_selection;
	const plan = { employee: employee, day: sp_item.day, shift_plan: item };
	const shiftname = shift.description ? shift.name + " - " + shift.description : shift.name;
	const tooltip_alerta = <Tooltip className="tooltip-alerta">
		<ul>
			{item.alert_min_rest_hours && (<li><GoAlert className="tooltip-icon icon-alert" /> No se cumple el mínimo de horas entre turnos</li>)}
			{item.alert_max_consecutive_working_days && (<li><GoAlert className="tooltip-icon icon-alert" /> No se cumple el máximo de días de trabajo consecutivos</li>)}
		</ul>
	</Tooltip>;
	const time_intervals = item.time_intervals_from_manual ? item.time_intervals : shift.time_intervals;
	// console.log(time_intervals);
	const total_raw = time_intervals ? calculateWorkTime(time_intervals) : 0;
	const total = formatSecondsToTime(total_raw, "hm");


	// console.log(sp_item.day);
	// console.log(item);
	return (
		<li
			key={key}
			title={`${shiftname}`}
			className="shift-item shift-item-intervals"
			style={{
				backgroundColor: shift.background_color && shift.background_color,
				borderColor: shift.border_color && shift.border_color,
				color: getContrastColor(shift.background_color),
			}}
			onClick={(e) =>
				props.hasPrivileges && props.handleDaySelection(row, index, e)
			}
			onMouseEnter={() => setHoveredAssignmentId(key)}
			onMouseLeave={() => {
				// Sólo se limpia el hover si NO se está añadiendo un turno
				if (!props.isAddingShift) {
					setHoveredAssignmentId(null);
				}
			}}
		>
			<ContextMenuTrigger
				id={day_selection.length > 0 ? MENU_DAY_ID : MENU_SHIFT_ID}
				holdToDisplay={1000}
				collect={collect}
				onItemClick={
					day_selection.length > 0
						? props.handleDayMenuClick
						: props.handleShiftMenuClick
				}
				targetId={item.id}
				plan={plan}
			>

				{shift.id === props.default_shift.id && "Descanso"}
				{shift.id !== props.default_shift.id && shift.type !== "Trabajo" && shift.name}

				{shift.id !== props.default_shift.id && shift.type === "Trabajo" && (
					<div className="shift-item-content">
						<div className="shift-info-left">
							{(time_intervals && time_intervals.length > 0) &&
								<ul className="time-intervals">
									{getShiftIntervals(time_intervals, shift.background_color)}
								</ul>
							}

							{item.function ?
								<span title={item.function.name} className="shift-function substring"
									style={{
										backgroundColor: funcion.background_color ? funcion.background_color : getContrastColor(shift.background_color),
										borderColor: funcion.border_color && funcion.border_color,
										color: funcion.background_color ? getContrastColor(funcion.background_color) : getContrastColor(getContrastColor(shift.background_color)),
									}}
								>{item.function.name}</span> :
								<span title="Sin función" className="shift-function substring"
									style={{
										backgroundColor: getContrastColor(shift.background_color),
										color: getContrastColor(getContrastColor(shift.background_color)),
									}}
								>Sin función</span>}
						</div>

						<div className="shift-info-right">
							<div title={shift.name} className="shift-badge">{shift.name.substring(0, 3)}</div>
							<div className="total-badge">{total}</div>
						</div>
					</div>)
				}

				{item.locked && <FaLock className="plan-icon icon-locked" />}
				{item.time_intervals_from_override_time_registrations && (
					<ImClock className="plan-icon icon-clock" />
				)}
				{item.comments && (
					<FaComment className="plan-icon icon-comments" />
				)}
				{(item.alert_min_rest_hours || item.alert_max_consecutive_working_days) && (
					<OverlayTrigger overlay={tooltip_alerta}>
						<GoAlert className="plan-icon icon-alert" />
					</OverlayTrigger>
				)}
				{editedTimes && <RiPencilFill className="plan-icon icon-edited" />}

			</ContextMenuTrigger>

			{/* Renderizamos el menú de hover solo si este assignment está en hover */}
			{hoveredAssignmentId === key && (
				<PlanShiftHoverMenu
					plan={plan} // Se pasa el contexto completo (empleado, día y asignación)
					onAdd={(update_employee_ids) => props.updateEmployeeIdsAndGetAllAssignments(update_employee_ids)}
					onEdit={() => props.handleEditShift(plan)}
					onDelete={() => props.handleRemoveShift(plan, plan.shift_plan.id)}
				/>
			)}
		</li>
	);
});


const getGridAssignedShifts = (props, hoveredAssignmentId, setHoveredAssignmentId, employee, sp_item, row, index) =>
	sp_item.assignments.map((item) => {
		const shift = item.shift;
		const editedTimes = item.time_intervals_from_manual && item.time_intervals && item.time_intervals.length > 0;
		const day_selection = props.day_selection;
		const plan = {
			employee: employee,
			day: sp_item.day,
			shift_plan: item,
		};
		const key = item.id === -1 ? `empty-${row}-${index}` : item.id; // Usamos el id de la asignación como key
		const shiftname = shift.description ? shift.name + " - " + shift.description : shift.name;
		const tooltip_alerta = <Tooltip className="tooltip-alerta">
			<ul>
				{item.alert_min_rest_hours && (<li><GoAlert className="tooltip-icon icon-alert" /> No se cumple el mínimo de horas entre turnos</li>)}
				{item.alert_max_consecutive_working_days && (<li><GoAlert className="tooltip-icon icon-alert" /> No se cumple el máximo de días de trabajo consecutivos</li>)}
			</ul>
		</Tooltip>;

		// console.log(sp_item.day);
		// console.log(item);
		return (
			<li
				key={shift.id}
				title={`${shiftname}`}
				className="shift-item"
				style={{
					backgroundColor: shift.background_color && shift.background_color,
					borderColor: shift.border_color && shift.border_color,
					color: getContrastColor(shift.background_color),
				}}
				onClick={(e) =>
					props.hasPrivileges && props.handleDaySelection(row, index, e)
				}
				onMouseEnter={() => setHoveredAssignmentId(key)}
				onMouseLeave={() => {
					// Sólo se limpia el hover si NO se está añadiendo un turno
					if (!props.isAddingShift) {
						setHoveredAssignmentId(null);
					}
				}}
			>

				<ContextMenuTrigger
					id={day_selection.length > 0 ? MENU_DAY_ID : MENU_SHIFT_ID}
					holdToDisplay={1000}
					collect={collect}
					onItemClick={
						day_selection.length > 0
							? props.handleDayMenuClick
							: props.handleShiftMenuClick
					}
					targetId={item.id}
					plan={plan}
				>

					{shift.id !== props.default_shift.id
						? shift.name.substring(0, 3)
						: "-"}

					{item.locked && <FaLock className="plan-icon icon-locked" />}
					{item.time_intervals_from_override_time_registrations && (
						<ImClock className="plan-icon icon-clock" />
					)}
					{item.comments && (
						<FaComment className="plan-icon icon-comments" />
					)}
					{(item.alert_min_rest_hours || item.alert_max_consecutive_working_days) && (
						<OverlayTrigger overlay={tooltip_alerta}>
							<GoAlert className="plan-icon icon-alert" />
						</OverlayTrigger>
					)}
					{editedTimes && <RiPencilFill className="plan-icon icon-edited" />}

				</ContextMenuTrigger>
				{/* Renderizamos el menú de hover solo si este assignment está en hover */}
				{hoveredAssignmentId === key && (
					<PlanShiftHoverMenu
						plan={plan} // Se pasa el contexto completo (empleado, día y asignación)
						onAdd={(update_employee_ids) => props.updateEmployeeIdsAndGetAllAssignments(update_employee_ids)}
						onEdit={() => props.handleEditShift(plan)}
						onDelete={() => props.handleRemoveShift(plan, plan.shift_plan.id)}
					/>
				)}
			</li>
		);
	});

const getShiftIntervals = (time_intervals, color) =>
	time_intervals.map((item, i) => {
		if (item.time_type.work_time) {
			// Convertir las horas de UTC a la zona horaria local del usuario
			const start = convertFromUTC(item.start).replace(":00", "");
			const end = convertFromUTC(item.end).replace(":00", "");

			return (
				<li key={i} className="time-interval" style={{ borderColor: color }}>
					{start}-{end}h
				</li>
			);
		}
		return null; // Devuelve null en lugar de una cadena vacía para evitar nodos innecesarios en el renderizado
	});



// celdas grid combo turnos + ubicaciones
const getAssignedShiftsWithUbications = (props, hoveredAssignmentId, setHoveredAssignmentId, employee, sp_item, row, index) =>
	sp_item.assignments.map((item) => {
		// console.log(sp_item);
		// console.log(item);
		// console.log(props);

		const shift = item.shift;
		const day_selection = props.day_selection;
		const plan = {
			employee: employee,
			day: sp_item.day,
			shift_plan: item,
		};
		const key = item.id === -1 ? `empty-${row}-${index}` : item.id; // Usamos el id de la asignación como key
		// time intervals editados
		const editedTimes = item.time_intervals && item.time_intervals.find(item => item.time_type.work_time === false);
		const shiftname = shift.description ? shift.name + " - " + shift.description : shift.name;

		const time_intervals = item.time_intervals_from_manual ? item.time_intervals : shift.time_intervals;
		const total_raw = time_intervals ? calculateWorkTime(time_intervals) : 0;
		const total = formatSecondsToTime(total_raw, "hm");

		const tooltip_alerta = <Tooltip className="tooltip-alerta">
			<ul>
				{item.alert_min_rest_hours && (<li><GoAlert className="tooltip-icon icon-alert" /> No se cumple el mínimo de horas entre turnos</li>)}
				{item.alert_max_consecutive_working_days && (<li><GoAlert className="tooltip-icon icon-alert" /> No se cumple el máximo de días de trabajo consecutivos</li>)}
			</ul>
		</Tooltip>;

		return (
			<li
				key={shift.id}
				title={`${shiftname}`}
				className={`shift-item`}
				style={{
					backgroundColor: shift.background_color && shift.background_color,
					borderColor: shift.border_color && shift.border_color,
					color: getContrastColor(shift.background_color),
				}}
				onClick={(e) =>
					props.hasPrivileges && props.handleDaySelection(row, index, e)
				}
				onMouseEnter={() => setHoveredAssignmentId(key)}
				onMouseLeave={() => {
					// Sólo se limpia el hover si NO se está añadiendo un turno
					if (!props.isAddingShift) {
						setHoveredAssignmentId(null);
					}
				}}
			>
				<ContextMenuTrigger
					id={day_selection.length > 0 ? MENU_DAY_ID : MENU_SHIFT_ID}
					holdToDisplay={1000}
					collect={collect}
					onItemClick={
						day_selection.length > 0
							? props.handleDayMenuClick
							: props.handleShiftMenuClick
					}
					targetId={item.id}
					plan={plan}
				>
					{shift.id === props.default_shift.id && "Descanso"}
					{shift.id !== props.default_shift.id && shift.type !== "Trabajo" && shift.name}

					{shift.id !== props.default_shift.id && shift.type === "Trabajo" && (
						<div className="shift-item-content">
							<div className="shift-info flex-between">
								{(time_intervals && time_intervals.length > 0) &&
									<ul className="time-intervals">
										{getShiftIntervals(time_intervals, shift.background_color)}
									</ul>
								}
								<div className="shift-info-right">
									<div className="shift-badge" title={shift.name}>{shift.name.substring(0, 3)}</div>
									<div className="total-badge">{total}</div>
								</div>
							</div>

							{(item.ubications && item.ubications.length > 0) ?
								<ul className="ubication-list">
									{getShiftUbications(props, sp_item, item, employee)}
								</ul>
								:
								<span className="shift-ubication substring"
									style={{
										backgroundColor: getContrastColor(shift.background_color),
										color: getContrastColor(getContrastColor(shift.background_color)),
									}}
								>Sin ubicación</span>}
						</div>)
					}

					{item.locked && <FaLock className="plan-icon icon-locked" />}
					{item.time_intervals_from_override_time_registrations && (
						<ImClock className="plan-icon icon-clock" />
					)}
					{item.comments && (
						<FaComment className="plan-icon icon-comments" />
					)}
					{(item.alert_min_rest_hours || item.alert_max_consecutive_working_days) && (
						<OverlayTrigger overlay={tooltip_alerta}>
							<GoAlert className="plan-icon icon-alert" />
						</OverlayTrigger>
					)}
					{editedTimes && <RiPencilFill className="plan-icon icon-edited" />}

				</ContextMenuTrigger>
				{/* Renderizamos el menú de hover solo si este assignment está en hover */}
				{hoveredAssignmentId === key && (
					<PlanShiftHoverMenu
						plan={plan} // Se pasa el contexto completo (empleado, día y asignación)
						onAdd={(update_employee_ids) => props.updateEmployeeIdsAndGetAllAssignments(update_employee_ids)}
						onEdit={() => props.handleEditShift(plan)}
						onDelete={() => props.handleRemoveShift(plan, plan.shift_plan.id)}
					/>
				)}
			</li>
		);
	});


const getShiftUbications = (props, sp_item, item, employee) => {
	const ubications = item.ubications;
	const shift = item.shift;

	if (ubications !== undefined && ubications.length > 0 && shift.type === "Trabajo") {
		const plan = {
			employee: employee,
			day: sp_item.day,
			shift_plan: item,
		};
		var lis = ubications.map((ubication, i) => {
			return (
				<li
					key={i}
					title={`${ubication.name} ${ubication.description ? "-" + ubication.description : ""}`}
					className="ubication-item shift-ubication"
					style={{
						backgroundColor: ubication.background_color ? ubication.background_color : getContrastColor(shift.background_color),
						borderColor: ubication.border_color && ubication.border_color,
						color: ubication.background_color ? getContrastColor(ubication.background_color) : getContrastColor(getContrastColor(shift.background_color)),
					}}
				>
					<ContextMenuTrigger
						id={MENU_SHIFT_ID}
						holdToDisplay={1000}
						collect={collect}
						onItemClick={props.handleShiftMenuClick}
						targetId={item.id}
						plan={plan}
						isEditOnly={true}
					>
						{ubication.name}
					</ContextMenuTrigger>
				</li>
			);
		});
		return lis;
	}
};


// celdas plan normal
const getPlanRowContent = (props, hoveredAssignmentId, setHoveredAssignmentId, employee, plans, row) => plans.map((item, i) => {
	const checked = item.checked || false;

	const cut = props.day_selection_copy.length > 0 && props.day_selection_copy.find(item => item.row === row && item.index === i);
	const dashed = props.day_selection_dashed.length > 0 && props.day_selection_dashed.find(item => item.row === row && item.index === i);

	if (item.active) {
		if (item.assignments.length === 0) {
			item.assignments = [{ id: -1, shift: props.default_shift }];
		}

		// vista grid plan (2)
		if (props.plan_grid_view === PLAN_GRID_VIEWS.grid) {
			return (
				<td
					key={i}
					className={`shift-cell btn-checkbox ${checked ? "active" : ""} ${cut ? "cut" : ""} ${dashed ? "dashed" : ""}`}
					onDragOver={(e) => onDragOver(e)}
					onDrop={(e) => onShiftsDrop(e, props, row, i)}
					data={{ action: "paste-shift" }}
				>
					<ContextMenuTrigger
						id={MENU_DAY_ID}
						holdToDisplay={1000}
						collect={collect}
						hasShifts={item.shifts ? true : false}
						onItemClick={props.handleDayMenuClick}
					>
						<input
							type="checkbox"
							id={`day_${row}_${i}`}
							onChange={(e) =>
								props.hasPrivileges && props.handleDaySelection(row, i, e)
							}
							checked={checked}
						/>
						<label
							htmlFor={`day_${row}_${i}`}
							className="btn-check-label"
							onContextMenu={() => props.selectSingleDay(row, i)}
							draggable
							onDragEnter={(e) => props.selectSingleDay(row, i)}
						></label>

						<ul className="shift-list-items">
							{getGridAssignedShifts(props, hoveredAssignmentId, setHoveredAssignmentId, employee, item, row, i)}
						</ul>
					</ContextMenuTrigger>
				</td>
			);
		}

		// vista ubicaciones (3)
		if (props.plan_grid_view === PLAN_GRID_VIEWS.ubications) {
			return (
				<td
					key={i}
					className={`shift-cell shift-cell-ubications btn-checkbox ${checked ? "active" : ""} ${cut ? "cut" : ""} ${dashed ? "dashed" : ""}`}
					onDragOver={(e) => onDragOver(e)}
					onDrop={(e) => props.isUbicationListSelected ? onDayUbicationsDrop(e, props, row, i) : onShiftsDrop(e, props, row, i)}
					data={{ action: "paste-shift" }}
				>
					<ContextMenuTrigger
						id={MENU_DAY_ID}
						holdToDisplay={1000}
						collect={collect}
						hasShifts={item.shifts ? true : false}
						onItemClick={props.handleDayMenuClick}
					>
						<input
							type="checkbox"
							id={`day_${row}_${i}`}
							onChange={(e) =>
								props.hasPrivileges && props.handleDaySelection(row, i, e)
							}
							checked={checked}
						/>
						<label
							htmlFor={`day_${row}_${i}`}
							className="btn-check-label"
							onContextMenu={() => props.selectSingleDay(row, i)}
							draggable
							onDragEnter={(e) => !props.isUbicationListSelected && props.selectSingleDay(row, i)}
						></label>
						<ul className="shift-list-items">
							{getAssignedShiftsWithUbications(props, hoveredAssignmentId, setHoveredAssignmentId, employee, item, row, i)}
						</ul>
					</ContextMenuTrigger>
				</td>
			);
		}

		// vista planificación default (1)
		return (
			<td
				key={i}
				className={`shift-cell shift-cell-intervals btn-checkbox ${checked ? "active" : ""} ${cut ? "cut" : ""} ${dashed ? "dashed" : ""}`}
				onDragOver={(e) => onDragOver(e)}
				onDrop={(e) => onShiftsDrop(e, props, row, i)}
				data={{ action: "paste-shift" }}
			>
				<ContextMenuTrigger
					id={MENU_DAY_ID}
					holdToDisplay={1000}
					collect={collect}
					hasShifts={item.shifts ? true : false}
					onItemClick={props.handleDayMenuClick}
				>
					<input
						type="checkbox"
						id={`day_${row}_${i}`}
						onChange={(e) =>
							props.hasPrivileges && props.handleDaySelection(row, i, e)
						}
						checked={checked}
					/>
					<label
						htmlFor={`day_${row}_${i}`}
						className="btn-check-label"
						onContextMenu={() => props.selectSingleDay(row, i)}
						draggable
						onDragEnter={(e) => props.selectSingleDay(row, i)}
					></label>

					<ul className="shift-list-items">
						{getDefaultAssignedShifts(props, hoveredAssignmentId, setHoveredAssignmentId, employee, item, row, i)}
					</ul>
				</ContextMenuTrigger>
			</td>


		);
	} else {
		return <td key={i} className="shift-cell disabled"></td>;
	}
});

const onDragOver = (e) => {
	e.preventDefault();
}

const onShiftsDrop = (e, props, row, i) => {
	props.selectSingleDay(row, i);
	props.handleDayMenuClick(e, { action: "paste-shift" });
}

// const onUbicationsDrop = (e, props, plan) => {
// 	props.handleShiftMenuClick(e, { action: "paste-ubication", plan });
// }

const onDayUbicationsDrop = (e, props, row, i) => {
	props.selectSingleDay(row, i);
	props.handleDayMenuClick(e, { action: "paste-ubication" });
}


const getHourlyPlanRowContent = (props, employee, plans, row) => plans.map((day_plan, i) => {
	// vista diaria por horas
	return (
		<Fragment key={i}>
			<td
				className="shift-cell shift-cell-hours-main shift-cell-hours"
			>
				{/* dia */}
			</td>
			{getHourlyPlanRowContentList(props, employee, day_plan, row, i)}
		</Fragment>
	);
});


const getHourlyPlanRowContentList = (props, employee, day_plan, row, index) => day_plan.hours.map((hour, i) => {
	const shift_no_trabajo = hour.hour_assignments.length == 1 && hour.hour_assignments[0].assignment.shift.type != "Trabajo" ? hour.hour_assignments[0].assignment.shift : undefined;

	if (!day_plan.active) {
		return <td key={i} className="shift-cell disabled"></td>;
	}

	if (shift_no_trabajo) {
		let shift = shift_no_trabajo;
		// si no es turno de trabajo se muestra pero no se puede editar
		const shiftname = shift.description ? shift.name + " - " + shift.description : shift.name;
		return (
			<td
				className="shift-cell shift-cell-hours"
				key={i}
				title={`${shiftname}`}
				style={{
					backgroundColor: shift.background_color && shift.background_color,
				}}
				onDragOver={(e) => onDragOver(e)}
				onDrop={(e) => onShiftsDrop(e, props, row, index)}
				data={{ action: "paste-shift" }}
			>
				<span
					className="shift-item"
					style={{
						backgroundColor: shift.background_color && shift.background_color,
						borderColor: shift.border_color && shift.border_color,
						color: getContrastColor(shift.background_color),
					}}
				>
					{shift.name.substring(0, 3)}
				</span>
			</td>
		)
	}
	else if (hour.pinta) {
		// si hay horas trabajadas se pintan en los diferentes turnos (editables)
		return (
			<td
				className="shift-cell shift-cell-hours btn-checkbox"
				onDragOver={(e) => onDragOver(e)}
				onDrop={(e) => onShiftsDrop(e, props, row, index)}
				key={i}
			>
				<ul className="shift-list-items shift-list-items-hours">
					{getHourlyAssignedShifts(props, employee, day_plan, hour)}
				</ul>
			</td>
		)
	}
	return (
		// celda vacía
		<td
			className="shift-cell shift-cell-hours"
			onDragOver={(e) => onDragOver(e)}
			onDrop={(e) => onShiftsDrop(e, props, row, index)}
			key={i}
		>
		</td>
	)
});

const getHourlyAssignedShifts = (props, employee, day_plan, hour) => hour.hour_assignments.map((item) => {
	// console.log(props);
	const hour_assignment = item.assignment;
	const hour_count = item.count;
	const shift = hour_assignment.shift;
	const shiftname = shift.description ? shift.name + " - " + shift.description : shift.name;

	// console.log(day_plan);
	// console.log(item);
	var cellClass = "";

	if (shift && shift.type === "Trabajo") {
		if (hour_count > 0 && hour_count <= 0.25) {
			cellClass = "cell-q1";
		}
		else if (hour_count > 0.25 && hour_count <= 0.5) {
			cellClass = "cell-q2";
		}
		else if (hour_count > 0.5 && hour_count <= 0.75) {
			cellClass = "cell-q3";
		}
		else {
			cellClass = "cell-full";
		}
	}

	const plan = {
		employee: employee,
		day: day_plan.day,
		shift_plan: hour_assignment,
	};

	if (!item.pinta) {
		return '';
	}


	return (
		<li
			key={shift.id}
			title={`${shiftname}`}
			className={`shift-item ${cellClass}`}
			style={{
				backgroundColor: shift.background_color && shift.background_color,
				borderColor: shift.border_color && shift.border_color,
				color: getContrastColor(shift.background_color),
			}}
			onClick={() => props.handleEditShift(plan)}
		>
			<ContextMenuTrigger
				id={MENU_SHIFT_ID}
				holdToDisplay={1000}
				collect={collect}
				onItemClick={props.handleShiftMenuClick}
				targetId={hour_assignment.id}
				plan={plan}
				isEditOnly={true}
			>
				{shift.name.substring(0, 3)}
			</ContextMenuTrigger>
		</li>
	);
});


const getPlanRows = (props, hoveredAssignmentId, setHoveredAssignmentId) => props.plan_assignments.map((assignment, i) => {
	const employee = assignment.employee;
	// console.log(employee);
	const funct =
		employee.employee_with_function &&
			employee.employee_with_function.function
			? employee.employee_with_function.function.name
			: "";
	const plans = assignment.plans;

	var text_ubication = "";

	if (employee.employee_with_ubication && employee.employee_with_ubication.ubication) {
		const ubication = employee.employee_with_ubication.ubication;
		text_ubication =
			(<>
				en {ubication.name}
			</>)
	}

	const employee_name = employee.name + " " + employee.surname;

	return (
		<tr key={i}>
			<th className="main-col">
				<div className="th-wrap">
					<div className="employee-main">
						<Link
							to={`/employees/${employee.id}`}
							target="_blank"
							className="employee-link-avatar"
							title={employee_name}
						>
							<Avatar
								userId={employee.id}
								className="avatar-xxs"
								alt={employee_name}
							/>
						</Link>
						<Link
							to={`/employees/${employee.id}`}
							target="_blank"
							className="employee-link-name substring"
							title={employee_name}
						>
							{employee_name}
						</Link>
					</div>

					<span className="employee-info">
						<span title={funct} className="employee-function substring">{funct}</span>
						<span title={text_ubication} className="employee-ubication substring">{text_ubication}</span>
					</span>

				</div>
			</th>

			{props.plan_grid_view === PLAN_GRID_VIEWS.hours ?
				getHourlyPlanRowContent(props, employee, plans, i)
				:
				getPlanRowContent(props, hoveredAssignmentId, setHoveredAssignmentId, employee, plans, i)
			}
		</tr>
	);
});

const PlanTableBody = (props) => {
	const [hoveredAssignmentId, setHoveredAssignmentId] = useState(null);

	return <tbody>{getPlanRows(props, hoveredAssignmentId, setHoveredAssignmentId)}</tbody>;
};

export default PlanTableBody;
