import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FiActivity, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { TabletOrDesktop, Mobile } from 'utils/responsive';
import uuidv4 from 'uuid/v4';
import { plansService } from "services";
import { convertFromUTC } from 'utils/datetime';

class Dashboard_WeekShifts extends Component {
	constructor(props) {
		super(props);
		//  console.log(props);
		const today = new Date();

		this.state = {
			period: today,
			is_current: true,
			plan_assignments: [],
		};
	}

	componentDidMount() {
		this.getWeekPlan();
	}

	async getWeekPlan() {
		const { period } = this.state;
		const employee_id = this.props.user.id;

		const start = moment(period).startOf('week').format('YYYY-MM-DD');
		const end = moment(period).endOf('week').format('YYYY-MM-DD');

		const response = await plansService.getPlanAssignments({
			employee_id,
			start,
			end,
		});
		var plan_assignments = [];
		// console.log(response);

		if (response.ok && response.data.length > 0) {
			plan_assignments = response.data[0].plans;
		}

		this.setState({
			plan_assignments,
		});
	}

	getHeadDay = (d) => {
		const day = moment(d).format("dd");
		const dayNum = moment(d).format("DD");

		return (
			<th key={uuidv4()} className="th-day">
				{day}<br />
				{dayNum}
			</th>
		);
	};

	getBodyCell = (item) => {
		if (item.assignments.length === 0) {
			return (
				<td key={uuidv4()} className="td-day-empty">
					-
				</td>)
		}
		return (
			<td key={uuidv4()} className="td-day">
				<ul>
					{this.getDayAssignments(item)}
				</ul>
			</td>
		);
	};

	getHeadCells = () => this.state.plan_assignments.map((item) => {
		return this.getHeadDay(item.day);
	});


	getBodyCells = () => this.state.plan_assignments.map((item) => {
		return this.getBodyCell(item);
	});

	getDayAssignments = (item) => item.assignments.map((item) => {
		const shift = item.shift;
		var ubication_info = "";
		var function_info = "";

		if (item.ubications && item.ubications.length > 0) {
			var text_ubication = "";

			item.ubications.map((ubication, i) => {
				text_ubication += ubication.name;

				if (i < item.ubications.length)
					text_ubication + ", ";
			});

			ubication_info = <><FaMapMarkerAlt className="icon" /> {text_ubication}</>
		}
		if (item.function) {
			function_info = item.function.name;
		}

		const time_intervals = item.time_intervals_from_manual ? item.time_intervals : shift.time_intervals;

		return (
			<li
				key={item.id}
				className="day-assignment"
				style={{ borderColor: shift.background_color }}
			>
				<div
					className="day-assignment-head"
					style={{ backgroundColor: shift.background_color }}
				>
					{shift.name}
				</div>
				<div className="day-assignment-content">
					{time_intervals && time_intervals.length > 0 && (<ul className="time-intervals">{this.getShiftIntervals(time_intervals)}</ul>)}
					{item.function && (<span className="function-info">{function_info}</span>)}
					{item.ubications && item.ubications.length > 0 && (<span className="ubication-info">{ubication_info}</span>)}
				</div>
			</li>
		);
	});

	getMobileBodyRows = () => this.state.plan_assignments.map((item, i) => {
		return (
			<tr key={i} className="td-day">
				{this.getHeadDay(item.day)}
				{this.getBodyCell(item)}
			</tr>
		);
	});

	getShiftIntervals = (time_intervals) =>
		time_intervals.map((item, i) => {
			// Excluir tipos de tiempo específicos
			const excludedTypes = ["Descanso", "Permiso", "Rest", "Permit"];
			if (!excludedTypes.includes(item.time_type.name)) {
				// Convertir las horas de UTC a la zona horaria local del usuario
				const start = convertFromUTC(item.start).replace(":00", "");
				const end = convertFromUTC(item.end).replace(":00", "");

				return (
					<li key={i} className="time-interval">
						{start}-{end}h
					</li>
				);
			}
			return null; // Devuelve null para evitar nodos vacíos en el renderizado
		});



	// updating active week +/- 1
	handleChangeWeek = (sum) => {
		const period = this.state.period;
		const newPeriod = moment(period).add(sum, 'weeks');
		const today = new Date();
		const current_week = moment(today).format('W');
		const new_period = newPeriod.format('W');
		const is_current = current_week === new_period;
		this.setState(
			{
				period: newPeriod.toDate(),
				is_current
			},
			() => {
				this.getWeekPlan();
			}
		);
	};

	getCardTop = () => {
		const { period, is_current } = this.state;

		const start = moment(period).startOf('week').format('DD/MM');
		const end = moment(period).endOf('week').format('DD/MM');
		const periodo = start + " - " + end;
		const titulo = is_current ? "Turnos de esta semana" : "Turnos semana";

		return (
			<div className="heading">
				<h2>
					{titulo}
					{!is_current && <span className="periodo">{periodo}</span>}
				</h2>
				<div className="heading-actions">
					<button
						className="btn-transparent calendar-left"
						onClick={() => this.handleChangeWeek(-1)}
					>
						<FiChevronLeft />
					</button>

					<button
						className="btn-transparent calendar-right"
						onClick={() => this.handleChangeWeek(1)}
					>
						<FiChevronRight />
					</button>
				</div>
			</div>
		);
	};

	render() {
		const { plan_assignments } = this.state;

		if (plan_assignments.length > 0) {
			return (
				<>
					<div className="card-top">
						{this.getCardTop()}
					</div>
					<div className="card-content">
						<TabletOrDesktop>
							<table className="table table-bordered table-week-plan">
								<thead>
									<tr>
										{this.getHeadCells()}
									</tr>
								</thead>
								<tbody>
									<tr>
										{this.getBodyCells()}
									</tr>
								</tbody>
							</table>
						</TabletOrDesktop>

						<Mobile>
							<table className="table table-week-plan table-mobile">
								<tbody>
									{this.getMobileBodyRows()}
								</tbody>
							</table>
						</Mobile>
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className="card-top">
						{this.getCardTop()}
					</div>
					<div className="card-content">
						<div className="empty">
							<FiActivity />
							<p>No hay ningún dato</p>
						</div>
					</div>
				</>
			);
		}
	}
}

const mapStateToProps = (reducers) => {
	return {
		user: reducers.authReducer.user,
	};
};

export default connect(mapStateToProps, null)(Dashboard_WeekShifts);
