import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";

const EmployeeFunctionForm = ({
  initialEmployeeFunction,
  functions,
  employee_ubications = [],
  errors,
  handleSubmit,
  isLoading,
  checkValidField,
  handleClose,
  showCancelButton = true,
  showAdvanced = true,
  cancelButtonText = "Cancelar",
  saveButtonText = "Guardar",
}) => {
  const [employee_function, setEmployeeFunction] = useState(initialEmployeeFunction);
  const [selected_ubications, setSelectedUbications] = useState([]);
  const [temp_ubication, setTempUbication] = useState("");
  const [new_item_error, setNewItemError] = useState(false);

  useEffect(() => {
    setEmployeeFunction(initialEmployeeFunction);

    if (initialEmployeeFunction.ubications) {
      const mappedUbications = initialEmployeeFunction.ubications.map(ubication => ({
        id: ubication.id,
        name: ubication.name,
      }));
      setSelectedUbications(mappedUbications);
    }
  }, [initialEmployeeFunction]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setEmployeeFunction((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSwitch = (event) => {
    // Si event.target existe, lo usamos; de lo contrario, asumimos que event ya es el objeto con id y checked
    const target = event.target || event;
    setEmployeeFunction((prev) => ({
      ...prev,
      [target.id]: target.checked,
    }));
  };

  const addUbication = () => {
    let isValid = true;
    let ubication;

    if (!temp_ubication) {
      isValid = false;
    } else {
      ubication = employee_ubications.find(item => item.id === parseInt(temp_ubication));
      if (!ubication) {
        isValid = false;
        setNewItemError(true);
      }
    }

    if (isValid) {
      const exists = selected_ubications.find(item => item.id === parseInt(temp_ubication));

      if (!exists) {
        setSelectedUbications((prev) => [...prev, { id: ubication.id, name: ubication.name }]);
      }

      setNewItemError(false);
      setTempUbication("");
    } else {
      setNewItemError(true);
    }
  };

  const removeUbication = (idUbication) => {
    setSelectedUbications((prev) =>
      prev.filter(item => item.id !== parseInt(idUbication))
    );
  };

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit({ ...employee_function, selected_ubications });
  };

  const optionFunctionList = functions.map((item) => (
    <option key={item.id} value={item.id}>{item.name}</option>
  ));

  const optionUbicationList = employee_ubications.map((item) => (
    <option key={item.id} value={item.id}>{item.name}</option>
  ));

  return (
    <form onSubmit={onSubmit}>
      <div className="modal-body">
        <FormGroup controlId="function_id">
          <FormLabel>Función</FormLabel>
          <FormControl as="select" value={employee_function.function_id} onChange={handleChange} isInvalid={checkValidField("function_id")}>
            <option value="-1">Seleccionar función</option>
            {optionFunctionList}
          </FormControl>
          <FormControl.Feedback type="invalid">
            {errors.function_id}
          </FormControl.Feedback>
        </FormGroup>

        {showAdvanced && (
          <>
            <FormGroup controlId="priority">
              <FormLabel>Prioridad</FormLabel>
              <FormControl as="select" value={employee_function.priority} onChange={handleChange} isInvalid={checkValidField("priority")}>
                <option value="-1">Seleccionar prioridad</option>
                {[...Array(10).keys()].map(i => (
                  <option key={i} value={i + 1}>{i + 1}</option>
                ))}
                <option value="100">100</option>
                <option value="1000">1000</option>
              </FormControl>
              <FormControl.Feedback type="invalid">
                {errors.priority}
              </FormControl.Feedback>
            </FormGroup>

            <div className="box-info">
              <ul>
                <li><strong>100:</strong> Completar turnos sigue siendo prioritario a la función</li>
                <li><strong>1000:</strong> Se prioriza la función a completar todos los turnos</li>
              </ul>
            </div>

            <FormGroup controlId="is_default">
              <FormLabel>Defecto</FormLabel>
              <Switch controlId="is_default" isOn={employee_function.is_default} handleToggle={handleSwitch} />
            </FormGroup>

            {(employee_ubications && employee_ubications.length > 0) && (
              <div
                className="form-group-flex form-group-main mb20"
              >
                <p className="mb5">Esta función estará disponible únicamente en las siguientes ubicaciones:</p>

                <div className="flex-group mb10">
                  <FormGroup controlId="temp_ubication">
                    <FormControl
                      type="text"
                      value={temp_ubication}
                      placeholder="Selecciona la ubicación"
                      onChange={(e) => setTempUbication(e.target.value)}
                      isInvalid={new_item_error}
                      as="select"
                    >
                      <option value="">Selecciona la ubicación</option>
                      {optionUbicationList}
                    </FormControl>
                  </FormGroup>
                  <button type="button" onClick={addUbication} className="btn btn-primary">Añadir</button>
                </div>
                <ul className="list-tags">
                  {selected_ubications.map((item, index) => (
                    <li key={index} className="tag-default tag-delete">
                      {item.name}
                      <button type="button" onClick={() => removeUbication(item.id)} className="btn-tag-delete btn-transparent">
                        <FiX />
                      </button>
                    </li>
                  ))}
                </ul>

                <p className='text-info f12'>Si no se selecciona ninguna ubicación la función estará disponible en todas las ubicaciones</p>
              </div>
            )}
          </>
        )}
      </div>

      <div className="modal-footer">
        {showCancelButton && (
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={handleClose}
          >
            {cancelButtonText}
          </button>
        )}
        <SubmitButton
          type="submit"
          isLoading={isLoading}
          text={saveButtonText}
        />
      </div>
    </form>
  );
};

export default EmployeeFunctionForm;
