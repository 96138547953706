import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel, Col, Row } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { errorFormating } from "utils/utils";
import Switch from "components/Switch";
import SubmitButton from "components/SubmitButton";
import { employeesService } from "services";
import * as groupsActions from 'actions/groupsActions';
import * as functionsActions from 'actions/functionsActions';
import * as ubicationsActions from 'actions/ubicationsActions';
import * as rulesActions from 'actions/rulesActions';
import * as shiftsActions from 'actions/shiftsActions';
import { getAppName } from 'utils/utils';
import EmployeeContractForm from './EmployeeContractForm';
import EmployeeGroupForm from './EmployeeGroupForm';
import EmployeeFunctionForm from './EmployeeFunctionForm';

import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { id } from 'date-fns/locale';
registerLocale("es", es);

class EmployeeEdit extends React.Component {
  constructor(props) {
    super(props);

    const today = moment().format("YYYY-MM-DD");

    let employee_contract = {
      contract_id: 0,
      start_day: today,
      end_day: null,
    }

    let employee_group = {
      group_id: props.group_id ? props.group_id : -1,
      start_day: today,
      end_day: null,
    };

    let employee_function = {
      function_id: 0,
      priority: null,
      is_default: true,
      ubications: [],
    };

    let employee_ubication = {
      ubication_id: 0,
      skill: null,
      is_default: true,
    }

    let employee_shift_pattern = {
      shift_pattern_id: 0,
      start_day: today,
      end_day: null,
      start_index: 0,
    };

    const length_options = this.setLengthOptions(28);

    this.state = {
      step: 1,
      employee: {
        name: "",
        surname: "",
        email: "",
        start_day: today,
        role: "EMPLOYEE",
        enabled: true,
        with_access: true,
      },
      isLoading: false,
      errors: {},
      employee_contract,
      employee_group,
      employee_function,
      employee_ubication,
      employee_shift_pattern,
      length_options
    };
  }

  componentDidMount() {
    // carga las listas solo si vienen vacías (cuando se viene desde Plan)
    const {
      contracts,
      getContracts,
      groups,
      getAllGroups,
      select_functions,
      getSelectableFunctions,
      select_ubications,
      getSelectableUbications,
      select_shift_patterns,
      getSelectableShiftPatterns,
    } = this.props;

    // console.log(select_functions);
    // console.log(select_ubications);

    if (!contracts || contracts.length === 0) {
      getContracts();
    }

    if (!groups || groups.length === 0) {
      getAllGroups();
    }

    if (!select_functions || select_functions.length === 0) {
      getSelectableFunctions();
    }

    if (!select_ubications || select_ubications.length === 0) {
      getSelectableUbications();
    }

    if (!select_shift_patterns || select_shift_patterns.length === 0) {
      getSelectableShiftPatterns();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step !== this.state.step) {
      this.updateModalTitle();
    }
  }

  updateModalTitle = () => {
    const { step } = this.state;
    const { setModalTitle } = this.props;
    let title = "";

    switch (step) {
      case 1:
        title = "Editar empleado"; // solo se muestra cuando se vuelve atrás
        break;
      case 2:
        title = "Configuración de empleado";
        break;
      case 3:
        title = "Asignar contrato";
        break;
      case 4:
        title = "Asignar grupo";
        break;
      case 5:
        title = "Asignar función";
        break;
      case 6:
        title = "Asignar ubicación";
        break;
      case 7:
        title = "Asignar rueda de turno";
        break;
      default:
        title = "Editar empleado";
    }

    if (setModalTitle) {
      setModalTitle(title); // Llama a la función para actualizar el título
    }
  };

  handleChange = (event, type) => {
    const { id, value } = event.target;
    var { length_options } = this.state;

    if (event.target.id == "shift_pattern_id") {
      const length = this.getLengthFromShiftPattern(event.target.value);
      length_options = this.setLengthOptions(length);
    }

    this.setState((prevState) => ({
      length_options,
      [type || "employee"]: {
        ...prevState[type || "employee"],
        [id]: value,
      },
    }));
  };

  handleDateChange = (id, date, type) => {
    const { employee, employee_contract, employee_group, employee_shift_pattern } = this.state;

    // al cambiar start_day de employee, actualizar también en employee_contract y employee_group
    if (!type && id === "start_day") {
      this.setState({
        employee: {
          ...employee,
          [id]: date ? moment(date).format("YYYY-MM-DD") : "",
        },
        employee_contract: {
          ...employee_contract,
          start_day: date ? moment(date).format("YYYY-MM-DD") : "",
        },
        employee_group: {
          ...employee_group,
          start_day: date ? moment(date).format("YYYY-MM-DD") : "",
        },
        employee_shift_pattern: {
          ...employee_shift_pattern,
          start_day: date ? moment(date).format("YYYY-MM-DD") : "",
        },
      });
    } else {
      this.setState((prevState) => ({
        [type || "employee"]: {
          ...prevState[type || "employee"],
          [id]: date ? moment(date).format("YYYY-MM-DD") : "",
        },
      }));
    }
  };

  handleSwitch = (event) => {
    const { employee } = this.state;
    const { id, checked } = event.target;

    this.setState({
      employee: {
        ...employee,
        [id]: checked,
      },
    });
  };

  checkValidField = (name) => {
    const { errors } = this.state;
    return !!errors[name];
  };

  // especifico de shift pattens
  setLengthOptions(limit) {
    const length_options = [];

    for (var i = 0; i < limit; i++) {
      length_options.push({
        index: i,
      });
    }

    return length_options;
  }

  // especifico de shift pattens
  getLengthFromShiftPattern(shift_pattern_id) {
    const { select_shift_patterns } = this.props;

    if (shift_pattern_id < 0) return 28;
    else {
      const shift_pattern = select_shift_patterns.find(
        (sp) => sp.id === parseInt(shift_pattern_id)
      );
      return shift_pattern.length;
    }
  }

  handleNext = async () => {
    const { step, employee } = this.state;
    const props = this.props;
    this.setState({ isLoading: true });

    try {
      if (step === 1) {
        let response;

        // GUARDAR EMPLEADO
        const roles = [];
        roles.push(employee.role);

        const temp_employee = {
          ...employee,
          roles,
        }

        // console.log(temp_employee);

        // TODO descomentar:
        if (employee.id) {
          // si está editando (volver atrás), update
          response = await employeesService.update(temp_employee);
        }
        else {
          response = await employeesService.add(temp_employee);
        }
        //response = { ok: true, id: 8024 };  // TEST RAPIDITO
        // console.log(response);

        if (response.ok) {
          toastr.success("¡Bien!", "Datos guardados correctamente");
          this.setState({
            step: step + 1,
            isLoading: false,
            employee: { ...employee, id: response.id }
          }, () => {
            // actualizar la lista de empleados
            props.reloadEmployeeData?.();
          });
        } else {
          this.setState({
            isLoading: false,
            submitted: true,
            errors: errorFormating(response)
          });
        }

      } else if (step === 2) {
        // pantalla de confirmación
        this.setState({ step: step + 1, isLoading: false, });

      } else if (step === 3) {
        // asignar contrato
      } else if (step === 4) {
        // asignar grupo
      } else if (step === 5) {
        // asignar función
      } else if (step === 6) {
        // asignar ubicación
        const { employee_ubication } = this.state;
        const response = await employeesService.addUbication(employee.id, employee_ubication);
        // const props = this.props;

        if (response.ok) {
          toastr.success("¡Bien!", "Ubicación asignada correctamente");
          this.setState({
            step: step + 1,
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
            submitted: true,
            errors: errorFormating(response)
          });
        }

      }
      else if (step === 7) {
        const { employee_shift_pattern } = this.state;
        // asignar rueda de turno
        const response = await employeesService.addShiftPattern(employee.id, employee_shift_pattern);

        if (response.ok) {
          toastr.success("¡Bien!", "Rueda de turnos asignada correctamente");
          this.setState({
            isLoading: false,
          }, () => {
            // FIN -> cerrar popup
            props.handleClose();
          });
        } else {
          this.setState({
            isLoading: false,
            submitted: true,
            errors: errorFormating(response)
          });
        }
      }

    } catch (error) {
      console.log(error);
      toastr.error("Error", "No se pudo guardar la información");
      this.setState({ errors: error.errors || {}, isLoading: false });
    }
  };

  handleBack = () => {
    this.setState((prevState) => ({
      step: prevState.step - 1,
    }));
  };

  handleJump = () => {
    this.setState((prevState) => ({
      step: prevState.step + 1,
    }));
  };

  submitEmployeeContract = async (employee_contract) => {
    const { step, employee } = this.state;
    this.setState({ isLoading: true });
    const response = await employeesService.addContract(employee.id, employee_contract);

    if (response.ok) {
      toastr.success("¡Bien!", "Contrato asignado correctamente");
      this.setState({
        step: step + 1,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  submitEmployeeGroup = async (employee_group) => {
    const { step, employee } = this.state;
    this.setState({ isLoading: true });
    const response = await employeesService.addGroup(employee.id, employee_group);
    const props = this.props;

    if (response.ok) {
      toastr.success("¡Bien!", "Grupo asignado correctamente");
      this.setState({
        step: step + 1,
        isLoading: false,
      }, () => {
        // PLAN => solo si el grupo guardado es el que venía por props, actualizar lista
        // TODO: si se va a usar en más sitios, refinar
        if (employee_group.group_id === props.group_id) {
          props.reloadGroupData?.();
        }
      });
    } else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  submitEmployeeFunction = async (employee_function) => {
    const { step, employee } = this.state;
    this.setState({ isLoading: true });
    const response = await employeesService.addFunction(employee.id, employee_function);
    // const props = this.props;

    if (response.ok) {
      toastr.success("¡Bien!", "Función asignada correctamente");
      this.setState({
        step: step + 1,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  renderStep = () => {
    const { step, employee, errors, employee_contract, employee_group, employee_function, employee_ubication, employee_shift_pattern, isLoading, length_options } = this.state;
    const { contracts, groups, select_functions, select_ubications, select_shift_patterns } = this.props;
    const hasAccess = employee.with_access;
    const appName = getAppName();
    const optionUbicationList = select_ubications.length > 0 && select_ubications.map((item, i) => {
      return (
        <option key={i} value={item.id}>{item.name}</option>
      )
    }, this);

    const optionShiftPatternList = select_shift_patterns.length > 0 && select_shift_patterns.map((item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.name}
        </option>
      );
    }, this);

    const optionIndexList = length_options.length > 0 && length_options.map((item) => {
      return (
        <option key={item.index} value={item.index}>
          Día {item.index + 1}
        </option>
      );
    }, this);

    switch (step) {
      case 1:
        return (
          <form>
            <FormGroup controlId="name">
              <FormLabel>Nombre <span className="label-required">*</span></FormLabel>
              <FormControl
                type="text"
                value={employee.name}
                onChange={this.handleChange}
                placeholder="Nombre empleado"
                isInvalid={this.checkValidField("name")}
              />
              <FormControl.Feedback type="invalid">
                {errors.name}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="surname">
              <FormLabel>Apellidos <span className="label-required">*</span></FormLabel>
              <FormControl
                type="text"
                value={employee.surname}
                onChange={this.handleChange}
                placeholder="Apellidos empleado"
                isInvalid={this.checkValidField("surname")}
              />
              <FormControl.Feedback type="invalid">
                {errors.surname}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="email">
              <FormLabel>Email <span className="label-required">*</span></FormLabel>
              <FormControl
                type="email"
                value={employee.email}
                onChange={this.handleChange}
                placeholder="nombre@miempresa.com"
                isInvalid={this.checkValidField("email")}
              />
              <FormControl.Feedback type="invalid">
                {errors.email}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="role">
              <FormLabel>Rol</FormLabel>
              <FormControl
                as="select"
                value={employee.role}
                onChange={this.handleChange}
                isInvalid={this.checkValidField("role")}
              >
                <option value="EMPLOYEE">Empleado</option>
                <option value="ADMIN">Administrador</option>
              </FormControl>
              <FormControl.Feedback type="invalid">
                {errors.role}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="start_day" className={`form-date ${employee.start_day === "" && "empty"}`}>
              <FormLabel>Fecha de contratación <span className="label-required">*</span></FormLabel>
              <DatePicker
                selected={employee.start_day ? moment(employee.start_day).toDate() : null}
                onChange={(date) => this.handleDateChange("start_day", date)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                locale="es"
                placeholderText="dd/mm/aaaa"
              />
              <FiCalendar />
              <FormControl.Feedback type="invalid">
                {errors.start_day}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup className="form-group-flex" controlId="with_access">
              <FormLabel className="label-normal">Acceso a {appName}</FormLabel>
              <Switch
                controlId="with_access"
                isOn={hasAccess}
                handleToggle={this.handleSwitch}
              />
            </FormGroup>
          </form>
        );
      case 2:
        return (
          <>
            <p><strong>El empleado se ha guardado correctamente.</strong></p>
            <p>A continuación puedes configurarlo paso a paso, haciendo click en el botón "Siguiente".</p>
          </>
        );
      case 3:
        return (
          <EmployeeContractForm
            initialEmployeeContract={employee_contract}
            contracts={contracts}
            errors={errors}
            handleSubmit={this.submitEmployeeContract}
            isLoading={isLoading}
            checkValidField={this.checkValidField}
            handleClose={this.handleBack}
            showFechaFin={false}
            cancelButtonText="Atrás"
            saveButtonText="Siguiente"
          />
        );
      case 4:
        return (
          <EmployeeGroupForm
            initialEmployeeGroup={employee_group}
            groups={groups}
            errors={errors}
            handleSubmit={this.submitEmployeeGroup}
            isLoading={isLoading}
            checkValidField={this.checkValidField}
            handleClose={this.handleJump}
            showFechaFin={false}
            cancelButtonText="Saltar"
            saveButtonText="Siguiente"
          />
        );
      case 5:
        return (
          <EmployeeFunctionForm
            initialEmployeeFunction={employee_function}
            functions={select_functions}
            errors={errors}
            handleSubmit={this.submitEmployeeFunction}
            isLoading={isLoading}
            checkValidField={this.checkValidField}
            handleClose={this.handleJump}
            showAdvanced={false}
            cancelButtonText="Saltar"
            saveButtonText="Siguiente"
          />
        );
      case 6:
        return (
          <form>
            <FormGroup controlId="ubication_id">
              <FormLabel>Ubicación</FormLabel>
              <FormControl
                onChange={(event) => this.handleChange(event, "employee_ubication")}
                onBlur={this.handleBlur}
                value={employee_ubication.ubication_id}
                isInvalid={this.checkValidField('ubication_id')}
                as="select">
                <option value="-1">Seleccionar ubicación</option>
                {optionUbicationList}
              </FormControl>

              <FormControl.Feedback type="invalid">
                {errors.ubication_id}
              </FormControl.Feedback>
            </FormGroup>
          </form>
        );
      case 7:
        return (
          <form>
            <FormGroup controlId="shift_pattern_id">
              <FormLabel>Rueda de turno</FormLabel>
              <FormControl
                onChange={(event) => this.handleChange(event, "employee_shift_pattern")}
                value={employee_shift_pattern.shift_pattern_id}
                isInvalid={this.checkValidField("shift_pattern_id")}
                as="select"
              >
                <option value="-1">Seleccionar rueda de turno</option>
                {optionShiftPatternList}
              </FormControl>

              <FormControl.Feedback type="invalid">
                {errors.shift_pattern_id}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="start_day" className={`form-date ${employee_shift_pattern.start_day === "" && "empty"}  ${this.checkValidField('start_day') && "is-invalid"}`}         >
              <FormLabel>Fecha inicio</FormLabel>
              <DatePicker
                selected={(employee_shift_pattern.start_day === "" || !employee_shift_pattern.start_day) ? employee_shift_pattern.start_day : moment(employee_shift_pattern.start_day).toDate()}
                onChange={(date) => this.handleDateChange("start_day", date, "employee_shift_pattern")}
                isInvalid={this.checkValidField("start_day")}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                locale='es'
                placeholderText="dd/mm/aaaa"
              />
              <FiCalendar />
              <FormControl.Feedback type="invalid">
                {errors.start_day}
              </FormControl.Feedback>
            </FormGroup>

            <FormGroup controlId="start_index">
              <FormLabel>Empezar por</FormLabel>
              <FormControl
                onChange={(event) => this.handleChange(event, "employee_shift_pattern")}
                value={employee_shift_pattern.start_index}
                isInvalid={this.checkValidField("start_index")}
                as="select"
              >
                <option value="-1">Seleccionar índice</option>
                {optionIndexList}
              </FormControl>

              <FormControl.Feedback type="invalid">
                {errors.start_index}
              </FormControl.Feedback>
            </FormGroup>
          </form>
        );
      default:
        return <div>El empleado ya está listo</div>;
    }
  };

  // REGLAS
  // Si no guarda contrato, no ofrecer posibilidad de grupo, funciones etc
  // Se puede saltar asignación, pero después de haber asignadi contrato
  // en un futuro actualizar lista de todo lo asignado

  render() {
    const { step, isLoading, employee } = this.state;
    let textButton;

    if (step === 1) {
      textButton = employee?.id ? "Actualizar empleado" : "Crear empleado";
    } else if (step === 7) {
      textButton = "Finalizar";
    } else {
      textButton = "Siguiente";
    }

    return (
      <>
        <div className="modal-body">
          {this.renderStep()}
        </div>

        <div className="modal-footer">
          {step == 2 && (
            <button type="button" onClick={this.handleBack} className="btn btn-outline-primary">
              Atrás
            </button>
          )}
          {step == 6 && (
            <button type="button" onClick={this.handleJump} className="btn btn-outline-primary">
              Saltar
            </button>
          )}
          {step == 7 && (
            <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">
              Cerrar
            </button>
          )}
          {(step < 3 || step === 6 || step === 7) && (
            <>
              <SubmitButton
                type="button"
                isLoading={isLoading}
                onClick={this.handleNext}
                text={textButton}
                loadingText="Guardando..."
              />
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (reducers, myProps) => ({
  ...reducers.employeesReducer,
  ...reducers.groupsReducer,
  ...reducers.functionsReducer,
  ...reducers.ubicationsReducer,
  ...reducers.rulesReducer,
  ...reducers.shiftsReducer,
  employee: myProps.employee,
});

const mapDispatchToProps = (dispatch) => ({
  getContracts: () => dispatch(rulesActions.getContracts()),
  getAllGroups: () => dispatch(groupsActions.getAllGroups()),
  getSelectableFunctions: () => dispatch(functionsActions.getSelectableFunctions()),
  getSelectableUbications: () => dispatch(ubicationsActions.getSelectableUbications()),
  getSelectableShiftPatterns: () => dispatch(shiftsActions.getSelectableShiftPatterns()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEdit);
