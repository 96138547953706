import React from 'react';
import { convertFromUTC } from 'utils/datetime';
class ShiftPreviewTooltip extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);
	}

	getSelectedDayTypes = (shift) => shift.day_types.map((item) => {
		return (
			<li key={item.id} aria-label={item.description} title={item.description} className="tag-default tag-comma" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
			</li>
		)
	});

	getSelectedGroups = (shift) => shift.groups.map((item) => {
		return (
			<li key={item.id} aria-label={item.description} title={item.description} className="tag-default tag-comma" style={{ backgroundColor: item.background_color && item.background_color }}>
				{item.name}
			</li>
		)
	});

	getTimeIntervalsBody = (time_intervals) => time_intervals.map((interval, i) => {
		const start = convertFromUTC(interval.start);
		const end = convertFromUTC(interval.end);

		return (
			<tr key={i}>
				<td>{interval.time_type.name}</td>
				<td>{start}</td>
				<td>{end}</td>
			</tr>
		)
	});

	render() {
		var { shift, time_intervals } = this.props;
		// console.log("tooltip");
		// console.log(time_intervals);
		// console.log(shift.time_intervals);

		const hasGroups = shift.has_groups && shift.groups && shift.groups.length > 0;
		const allowHourly = shift.allow_hourly_absence_request;
		var time_intervals_sobreescritos;
		var sobreescrito = false;

		function deepEqual(obj1, obj2) {
			if (obj1 === obj2) return true;
			if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

			let keys1 = Object.keys(obj1);
			let keys2 = Object.keys(obj2);

			if (keys1.length !== keys2.length) return false;

			for (let key of keys1) {
				if (!deepEqual(obj1[key], obj2[key])) return false;
			}

			return true;
		}

		// Función para comparar dos arrays
		function arraysAreEqual(arr1, arr2) {
			if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
			if (arr1.length !== arr2.length) return false;
			for (let i = 0; i < arr1.length; i++) {
				if (!deepEqual(arr1[i], arr2[i])) return false;
			}
			return true;
		}

		// Comprobar si los arrays son diferentes antes de la asignación
		if (time_intervals && time_intervals.length > 0 && !arraysAreEqual(time_intervals, shift.time_intervals)) {
			time_intervals_sobreescritos = time_intervals;
			sobreescrito = true; // Indica que ha sido sobreescrito porque son diferentes
		} else {
			time_intervals = shift.time_intervals;
		}

		if (!shift)
			return ""

		return (
			<div className="shift-preview">
				<div className="heading">
					<h2>[{shift.name}] {shift.description}</h2>
					<span className="shift-type">{shift.type}</span>
				</div>

				{(shift.type === "Ausencia" || shift.type === "Vacaciones") &&
					<p><strong>{allowHourly ? "SI" : "NO"}</strong> permite peticiones de <span className="lowercase">{shift.type}</span> por horas</p>
				}

				{this.props.shift.day_types && (
					<>
						<h3 className="subtitle">Tipos de día a los que aplica</h3>

						<ul className="list-tags">
							{this.getSelectedDayTypes(shift)}
						</ul>
					</>)
				}

				{(shift.type === "Trabajo" || allowHourly) && time_intervals && time_intervals.length > 0 && (
					<>
						<h3 className="subtitle">Periodos de tiempo</h3>

						<table className="table table-condensed table-zebra-reverse table-specialdays">
							<thead>
								{sobreescrito && (
									<tr>
										<th className="table-caption" colSpan={3}>Turno original</th>
									</tr>
								)}
								<tr>
									<th>Tipo</th>
									<th>Inicio</th>
									<th>Fin</th>
								</tr>
							</thead>
							<tbody>
								{this.getTimeIntervalsBody(time_intervals)}
							</tbody>
						</table>

						{sobreescrito && (
							<table className="table table-condensed table-zebra-reverse table-specialdays">
								<thead>
									<tr>
										<th className="table-caption" colSpan={3}>Sobreescrito</th>
									</tr>
									<tr>
										<th>Tipo</th>
										<th>Inicio</th>
										<th>Fin</th>
									</tr>
								</thead>
								<tbody>
									{this.getTimeIntervalsBody(time_intervals_sobreescritos)}
								</tbody>
							</table>
						)}

					</>)
				}

				{
					hasGroups && (
						<>
							<h3 className="subtitle">Grupos a los que aplica</h3>
							<ul className="list-tags">
								{this.getSelectedGroups(shift)}
							</ul>
						</>
					)
				}

			</div >
		)
	}
}


export default ShiftPreviewTooltip
