import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import moment from 'moment';
import EmployeeContractEdit from "./EmployeeContractEditModal";
import ContractEdit from "../Contracts//ContractEditModal";
import { showModal, hideModal } from "actions/modalActions";
import * as employeesActions from 'actions/employeesActions';
import { employeesService } from "services";
import { errorFormating } from "utils/utils";


class EmployeeContracts extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.getContracts = this.getContracts.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.openNewEmployeeContractModal = this.openNewEmployeeContractModal.bind(this);
		this.openEditEmployeeContractModal = this.openEditEmployeeContractModal.bind(this);
		this.openEditContractModal = this.openEditContractModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
	}

	componentDidMount() {
		this.getContracts();
	}

	async getContracts() {
		const employeeId = this.props.employee.id;
		await this.props.getEmployeeContracts(employeeId);
	}

	closeModal() {
		this.props.hideModal()
	}

	openNewEmployeeContractModal() {
		const { contracts, employee } = this.props;

		this.props.showModal({
			open: true,
			title: 'Asignar contrato',
			style: { width: '350px' },
			content: <EmployeeContractEdit handleClose={this.closeModal} contracts={contracts} employee={employee} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openEditEmployeeContractModal(employee_contract) {
		const { contracts, employee } = this.props;

		this.props.showModal({
			open: true,
			title: 'Editar asignación de contrato',
			style: { width: '350px' },
			content: <EmployeeContractEdit employee_contract={employee_contract} employee={employee} handleClose={this.closeModal} contracts={contracts} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openEditContractModal(employee_contract) {
		this.props.showModal({
			open: true,
			title: 'Editar contrato',
			style: { width: '860px' },
			content: <ContractEdit contract={employee_contract.contract} getContracts={this.getContracts} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteEmployeeContractModal(idContract) {
		this.props.showModal({
			open: true,
			title: 'Eliminar asignación',
			style: { width: '350px' },
			message: '¿Desea eliminar la asignación con el contrato? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idContract),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
			open: true,
			title: "Error al eliminar",
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (idContract) => {
		const response = await employeesService.removeContract(this.props.employee.id, idContract);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else {
			this.getContracts();
			this.closeModal();
		}
	}

	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="4" className="no-result">
					Todavía no hay ningún contrato asignado.
				</td>
			</tr>
		);
	}

	getRows = () => this.props.employee_contracts.map((employee_contract) => {

		return (
			<tr key={employee_contract.id}>
				<td>
					<button className="btn-transparent" onClick={() => this.openEditEmployeeContractModal(employee_contract)}>{employee_contract.contract.name}</button>
				</td>
				<td>
					{moment(employee_contract.start_day).format("DD/MM/YY")}
				</td>
				<td>
					{employee_contract.end_day ? moment(employee_contract.end_day).format("DD/MM/YY") : "-"}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openEditEmployeeContractModal(employee_contract)}>Editar asignación</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openEditContractModal(employee_contract)}>Editar contrato</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openDeleteEmployeeContractModal(employee_contract.id)}>Eliminar</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	getContent = () => {
		const { employee_contracts } = this.props;

		return (
			<>
				<table className="table table-groups table-zebra-reverse">
					<thead>
						<tr>
							<th>Contrato</th>
							<th>Inicio</th>
							<th>Fin</th>
							<th className="td-actions"><button type="button" title="Asignar contrato" onClick={this.openNewEmployeeContractModal} className="btn btn-new"><FiPlus /></button></th>
						</tr>
					</thead>
					<tbody>
						{employee_contracts.length === 0 ?
							this.getEmptyRow()
							:
							this.getRows()
						}
					</tbody>
				</table>
			</>
		);
	}
	render() {
		return (
			<>
				{this.getContent()}
			</>
		)
	}
}


const mapStateToProps = state => {
	return {
		employee_contracts: state.employeesReducer.employee_contracts,
		user: state.authReducer.user,
	}
}

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getEmployeeContracts: (id) => dispatch(employeesActions.getEmployeeContracts(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContracts);