import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FiPlus, FiMoreHorizontal } from "react-icons/fi";
import moment from 'moment';
import EmployeeShiftPatternEdit from "./EmployeeShiftPatternEditModal";
import ShiftPatternEdit from "../ShiftPatterns/ShiftPatternEditModal";
import { showModal, hideModal } from "actions/modalActions";
import * as employeesActions from 'actions/employeesActions';
import { employeesService } from "services";
import { errorFormating } from "utils/utils";


class EmployeeShiftPatterns extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.closeModal = this.closeModal.bind(this);
		this.openNewEmployeeShiftPatternModal = this.openNewEmployeeShiftPatternModal.bind(this);
		this.openEditEmployeeShiftPatternModal = this.openEditEmployeeShiftPatternModal.bind(this);
		this.openEditShiftPatternModal = this.openEditShiftPatternModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.getShiftPatterns = this.getShiftPatterns.bind(this);
	}

	componentDidMount() {
		this.getShiftPatterns();
	}

	async getShiftPatterns() {
		const employeeId = this.props.employee.id;
		await this.props.getEmployeeShiftPatterns(employeeId);
	}

	closeModal() {
		this.props.hideModal()
	}

	openNewEmployeeShiftPatternModal() {
		const { employee } = this.props;

		this.props.showModal({
			open: true,
			title: 'Asignar rueda de turnos',
			style: { width: '350px' },
			content: <EmployeeShiftPatternEdit handleClose={this.closeModal} employee={employee} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openEditEmployeeShiftPatternModal(employee_shift_pattern) {
		const { employee } = this.props;

		this.props.showModal({
			open: true,
			title: 'Editar asignación de rueda de turnos',
			style: { width: '350px' },
			content: <EmployeeShiftPatternEdit employee_shift_pattern={employee_shift_pattern} employee={employee} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openEditShiftPatternModal(employee_shift_pattern) {
		this.props.showModal({
			open: true,
			title: 'Editar rueda de turnos',
			style: { width: '850px' },
			content: <ShiftPatternEdit getShiftPatterns={this.getShiftPatterns} shift_pattern={employee_shift_pattern.shift_pattern} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteEmployeeShiftPatternModal(idShiftPattern) {
		this.props.showModal({
			open: true,
			style: { width: '400px' },
			title: 'Eliminar asignación',
			message: '¿Desea eliminar la asignación con la rueda de turnos? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idShiftPattern),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
			open: true,
			title: "Error al eliminar",
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (idShiftPattern) => {
		const response = await employeesService.removeShiftPattern(this.props.employee.id, idShiftPattern);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else {
			this.getShiftPatterns();
			this.closeModal();
		}
	}

	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="4" className="no-result">
					Todavía no hay ninguna rueda de turnos asignada.
				</td>
			</tr>
		);
	}

	getRows = () => this.props.employee_shift_patterns.map((employee_shift_pattern) => {

		return (
			<tr key={employee_shift_pattern.id}>
				<td>
					<button className="btn-transparent" onClick={() => this.openEditEmployeeShiftPatternModal(employee_shift_pattern)}>{employee_shift_pattern.shift_pattern.name}</button>
				</td>
				<td>
					{moment(employee_shift_pattern.start_day).format("DD/MM/YY")}
				</td>
				<td>
					{employee_shift_pattern.end_day ? moment(employee_shift_pattern.end_day).format("DD/MM/YY") : "-"}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openEditEmployeeShiftPatternModal(employee_shift_pattern)}>Editar asignación</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openEditShiftPatternModal(employee_shift_pattern)}>Editar rueda de turnos</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openDeleteEmployeeShiftPatternModal(employee_shift_pattern.id)}>Eliminar</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	getContent = () => {
		const { employee_shift_patterns } = this.props;
		if (this.props.employee_shift_patterns) {
			return (
				<table className="table table-shift-patterns table-zebra-reverse">
					<thead>
						<tr>
							<th>Rueda de turnos</th>
							<th>Inicio</th>
							<th>Fin</th>
							<th className="td-actions">
								<button type="button" title="Crear grupo" onClick={this.openNewEmployeeShiftPatternModal} className="btn btn-new"><FiPlus /></button>
							</th>
						</tr>
					</thead>
					<tbody>
						{employee_shift_patterns.length === 0 ?
							this.getEmptyRow()
							:
							this.getRows()
						}
					</tbody>
				</table>
			);
		}
	}
	render() {
		return (
			<>
				{this.getContent()}
			</>
		)
	}
}


const mapStateToProps = state => {
	return {
		employee_shift_patterns: state.employeesReducer.employee_shift_patterns,
		user: state.authReducer.user,
	}
}

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getEmployeeShiftPatterns: (id) => dispatch(employeesActions.getEmployeeShiftPatterns(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeShiftPatterns);