import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { FiCalendar } from "react-icons/fi";
import moment from "moment";
import SubmitButton from "components/SubmitButton";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

const EmployeeContractForm = ({
  initialEmployeeContract,
  contracts,
  errors,
  handleSubmit,
  isLoading,
  checkValidField,
  handleClose,
  showCancelButton = true,
  showFechaFin = true,
  cancelButtonText = "Cancelar",
  saveButtonText = "Guardar",
}) => {

  const optionList = contracts.map((item, i) => (
    <option key={i} value={item.id}>{item.name}</option>
  ));

  const [employee_contract, setContract] = useState(initialEmployeeContract);

  // Sincronizar el estado local con cambios en initialEmployeeContract
  useEffect(() => {
    setContract(initialEmployeeContract);
  }, [initialEmployeeContract]);

  const handleChange = (event) => {
    setContract({
      ...employee_contract,
      [event.target.id]: event.target.value,
    });
  };

  const handleDateChange = (id, date) => {
    setContract({
      ...employee_contract,
      [id]: date ? moment(date).format("YYYY-MM-DD") : "",
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    handleSubmit(employee_contract);
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="modal-body">
        <FormGroup controlId="contract_id">
          <FormLabel>Contrato</FormLabel>
          <FormControl
            as="select"
            value={employee_contract.contract_id}
            onChange={handleChange}
            isInvalid={checkValidField("contract_id")}
          >
            <option value="-1">Seleccionar contrato</option>
            {optionList}
          </FormControl>
          <FormControl.Feedback type="invalid">
            {errors.contract_id}
          </FormControl.Feedback>
        </FormGroup>

        <FormGroup controlId="start_day" className={`form-date ${(employee_contract.start_day === '' || employee_contract.start_day === null) && 'empty'}   ${checkValidField('start_day') && "is-invalid"}`}>
          <FormLabel>Fecha inicio</FormLabel>
          <DatePicker
            selected={(!employee_contract.start_day || employee_contract.start_day === "") ? employee_contract.start_day : moment(employee_contract.start_day).toDate()}
            onChange={(date) => handleDateChange("start_day", date)}
            className="form-control"
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/aaaa"
            locale='es'
            isInvalid={checkValidField("start_day")}
          />
          <FiCalendar />
          <FormControl.Feedback type="invalid">
            {errors.start_day}
          </FormControl.Feedback>
        </FormGroup>
        {showFechaFin && (
          <FormGroup controlId="end_day" className={`form-date ${checkValidField('end_day') && "is-invalid"}`}>
            <FormLabel>Fecha fin (opcional)</FormLabel>

            <DatePicker
              selected={(!employee_contract.end_day || employee_contract.end_day === "") ? null : moment(employee_contract.end_day).toDate()}
              onChange={(date) => handleDateChange("end_day", date)}
              isInvalid={checkValidField("end_day")}
              className="form-control"
              dateFormat="dd/MM/yyyy"
              locale='es'
              placeholderText="dd/mm/aaaa"
            />

            <FiCalendar />
            <FormControl.Feedback type="invalid">
              {errors.end_day}
            </FormControl.Feedback>
          </FormGroup>
        )}
      </div>

      <div className="modal-footer">
        {showCancelButton && <button type="button" onClick={handleClose} className="btn btn-outline-primary">{cancelButtonText}</button>}
        <SubmitButton
          type="submit"
          isLoading={isLoading}
          text={saveButtonText}
        />
      </div>
    </form>
  );
};

export default EmployeeContractForm;
