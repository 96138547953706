import React from "react";
import { Button } from "react-bootstrap";

export default ({
  isLoading,
  text = "Guardar",
  loadingText = "Guardando...",
  iconBefore,
  iconAfter,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button
    className={`SubmitButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {!isLoading && iconBefore && iconBefore}
    {!isLoading ? text : loadingText}
    {!isLoading && iconAfter && iconAfter}
  </Button>;
