import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FiPlus, FiMoreHorizontal, FiCheck } from "react-icons/fi";
import EmployeeFunctionEdit from "./EmployeeFunctionEditModal";
import FunctionEdit from "../Functions/FunctionEditModal";
import { showModal, hideModal } from "actions/modalActions";
import * as employeesActions from 'actions/employeesActions';
import { employeesService } from "services";
import { errorFormating } from "utils/utils";


class EmployeeFunctions extends React.Component {
	constructor(props) {
		super(props);
		// console.log(props);
		this.closeModal = this.closeModal.bind(this);
		this.openNewEmployeeFunctionModal = this.openNewEmployeeFunctionModal.bind(this);
		this.openEditEmployeeFunctionModal = this.openEditEmployeeFunctionModal.bind(this);
		this.openEditFunctionModal = this.openEditFunctionModal.bind(this);
		this.openAlertModal = this.openAlertModal.bind(this);
		this.getFunctions = this.getFunctions.bind(this);
	}

	componentDidMount() {
		this.getFunctions();
	}

	async getFunctions() {
		const employeeId = this.props.employee.id;
		await this.props.getEmployeeFunctions(employeeId);
	}

	closeModal() {
		this.props.hideModal()
	}

	openNewEmployeeFunctionModal() {
		const { functions, employee } = this.props;

		this.props.showModal({
			open: true,
			title: 'Asignar función',
			style: { width: '350px' },
			content: <EmployeeFunctionEdit handleClose={this.closeModal} functions={functions} employee={employee} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openEditEmployeeFunctionModal(employee_function) {
		const { functions, employee } = this.props;

		this.props.showModal({
			open: true,
			title: 'Editar asignación de función',
			style: { width: '350px' },
			content: <EmployeeFunctionEdit employee_function={employee_function} employee={employee} handleClose={this.closeModal} functions={functions} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openEditFunctionModal(employee_function) {
		this.props.showModal({
			open: true,
			title: 'Editar función',
			style: { width: '400px' },
			content: <FunctionEdit function={employee_function.function} getFunctions={this.getFunctions} handleClose={this.closeModal} />,
			closeModal: this.closeModal
		}, 'edit')
	}

	openDeleteEmployeeFunctionModal(idFunction) {
		this.props.showModal({
			open: true,
			title: 'Eliminar asignación',
			style: { width: '350px' },
			message: '¿Desea eliminar la asignación con la función? Esta acción no se puede deshacer',
			deleteAction: () => this.confirmDelete(idFunction),
			closeModal: this.closeModal
		}, 'delete')
	}

	openAlertModal(message) {
		this.props.showModal({
			open: true,
			title: "Error al eliminar",
			style: { width: '400px' },
			message: message,
			closeModal: this.closeModal
		}, 'alert')
	}

	confirmDelete = async (idFunction) => {
		const response = await employeesService.removeFunction(this.props.employee.id, idFunction);

		if (!response.ok) {
			let textError = errorFormating(response);
			this.openAlertModal(textError.id);
		}
		else {
			this.getFunctions();
			this.closeModal();
		}
	}

	getEmptyRow = () => {
		return (
			<tr>
				<td colSpan="4" className="no-result">
					Todavía no hay ninguna función asignada.
				</td>
			</tr>
		);
	}

	getRows = () => this.props.employee_functions.map((employee_function) => {

		return (
			<tr key={employee_function.id}>
				<td>
					<button className="btn-transparent" onClick={() => this.openEditEmployeeFunctionModal(employee_function)}>{employee_function.function.name}</button>
				</td>
				<td>
					{employee_function.priority && employee_function.priority !== -1 ? employee_function.priority : '-'}
				</td>
				<td>
					{employee_function.is_default && <FiCheck className="tick-default" />}
				</td>
				<td className="td-actions">
					<Dropdown>
						<Dropdown.Toggle variant="action"><FiMoreHorizontal /></Dropdown.Toggle>
						<Dropdown.Menu alignRight>
							<Dropdown.Item as="button" onClick={() => this.openEditEmployeeFunctionModal(employee_function)}>Editar asignación</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openEditFunctionModal(employee_function)}>Editar función</Dropdown.Item>
							<Dropdown.Item as="button" onClick={() => this.openDeleteEmployeeFunctionModal(employee_function.id)}>Eliminar</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		)
	});

	getContent = () => {
		const { employee_functions } = this.props;

		return (
			<>
				<table className="table table-groups table-zebra-reverse">
					<thead>
						<tr>
							<th>Función</th>
							<th>Prioridad</th>
							<th>Defecto</th>
							<th className="td-actions"><button type="button" title="Asignar función" onClick={this.openNewEmployeeFunctionModal} className="btn btn-new"><FiPlus /></button></th>
						</tr>
					</thead>
					<tbody>
						{employee_functions.length === 0 ?
							this.getEmptyRow()
							:
							this.getRows()
						}
					</tbody>
				</table>
			</>
		);
	}
	render() {
		return (
			<>
				{this.getContent()}
			</>
		)
	}
}


const mapStateToProps = state => {
	return {
		employee_functions: state.employeesReducer.employee_functions,
		user: state.authReducer.user,
	}
}

const mapDispatchToProps = dispatch => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }))
	},
	getEmployeeFunctions: (id) => dispatch(employeesActions.getEmployeeFunctions(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeFunctions);