import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import Avatar from './';

class Avatars extends Component {
	constructor(props) {
		super(props);
		// Generamos un ID único por instancia del componente
		this.instanceId = `avatars-${Math.random().toString(36).substr(2, 9)}`;
	}

	getAvatars = () => this.props.employees.map((employee, i) => {
		if (i > 2) return null;

		// Usamos el instanceId para asegurar una key única
		const uniqueKey = `${employee.id}-${this.instanceId}-${i}`;

		return (
			<li key={uniqueKey}>
				<Link to={`/employees/${employee.id}`}>
					<Avatar userId={employee.id} className="avatar-sm" alt={employee.name} />
				</Link>
			</li>
		)
	});

	render() {
		return (
			<ul className="avatar-collection">
				{this.getAvatars()}
			</ul>
		)
	}
}

export default Avatars;